import React from 'react';
import moment from 'moment';
import ReactMarkdown from "react-markdown";
//import './Comment.css';
import Avatar from '../Avatar/Avatar';


const Comment = (props) => {
  const { comment } = props;
  return (         
    <div className="comment">
      <div class="textSection">
        <div class="author">
          {/*<Avatar name={comment.author} /> */}
          {comment.author}
        </div>
        <div class="timestamp">{moment.unix(comment.created_utc).fromNow()}</div>
      </div>
      <div class="text">
        <ReactMarkdown>{comment.body}</ReactMarkdown>
        <ReactMarkdown source={comment.body} />
      </div>
    </div>
  );
};

export default Comment;
