import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/Card/Card';
import { fetchSubreddits, selectSubreddits } from '../../store/subRedditSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCat } from '@fortawesome/free-solid-svg-icons';
//import './Subreddits.css';
import {
  setSelectedSubreddit,
  selectSelectedSubreddit,
} from '../../store/redditSlice';

const Subreddits = () => {
  const dispatch = useDispatch();
  const subreddits = useSelector(selectSubreddits);
  const selectedSubreddit = useSelector(selectSelectedSubreddit);

  useEffect(() => {
    dispatch(fetchSubreddits());
  }, [dispatch]);

  return (
    <Card className="card Content-related">   
        <div className="Content-related-header">
          <div className="head">Cat Subreddits</div>
          <Skeleton />
        </div>

    {/*}    <div class="subreddit-wrapper interactive">
                <div class="subreddit-logo"></div>
                <div class="subreddit-name">Cool Cats</div>
                <div class="subreddit-money">945</div>
            </div>
    */} 
    
        {subreddits.map((subreddit) => (
            <div
            key={subreddit.id}
            className={`subreddit-wrapper interactive ${selectedSubreddit === subreddit.url && `selected-subreddit`}` } 
            type="button"
            onClick={() => dispatch(setSelectedSubreddit(subreddit.url))}
          >   
              <div className="subreddit-logo-bg">
              <img
                src={
                  subreddit.icon_img ||
                  //`'../../image/paw.svg`
                  `https://api.adorable.io/avatars/25/${subreddit.display_name}`
                }
                alt={`${subreddit.display_name}`}
                className="subreddit-logo"
                //style={{ border: `3px solid ${subreddit.primary_color}` }} //Sets the border colour of the icon, dont need that
              />
              </div>
                <div className="subreddit-name">
                    {subreddit.display_name}
                </div>
            </div>
          
    
        ))}

    </Card>
  );
};

export default Subreddits;
