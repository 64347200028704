import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import './Post.css';
import {
  TiArrowUpOutline,
  TiArrowUpThick,
  TiArrowDownOutline,
  TiArrowDownThick,
  TiMessage,
} from 'react-icons/ti';
import moment from 'moment';
import shortenNumber from '../../utils/shortenNumber';
import Card from '../../components/Card/Card';
import Comment from '../Comment/Comment';
import Avatar from '../Avatar/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCat, faArrowAltCircleUp, faArrowAltCircleDown, faComment } from '@fortawesome/free-solid-svg-icons'
//import { faComment } from '@fortawesome/free-light-svg-icons'


const Post = (props) => {
  const [voteValue, setVoteValue] = useState(0);

  const { post, onToggleComments } = props;

  /**
   * @param {number} newValue The new vote value
   */
  const onHandleVote = (newValue) => {
    if (newValue === voteValue) {
      setVoteValue(0);
    } else if (newValue === 1) {
      setVoteValue(1);
    } else {
      setVoteValue(-1);
    }
  };

  const renderUpVote = () => {
    if (voteValue === 1) {
      //return <TiArrowUpThick className="icon-action" />;
      return <FontAwesomeIcon icon={faArrowAltCircleUp} />;
    }
    //return <TiArrowUpOutline className="icon-action" />;
    return <FontAwesomeIcon icon={faArrowAltCircleUp} />;
  };

  const renderDownVote = () => {
    if (voteValue === -1) {
      //return <TiArrowDownThick className="icon-action" />;
      return <FontAwesomeIcon icon={faArrowAltCircleDown} />;
    }
    //return <TiArrowDownOutline className="icon-action" />;
    return <FontAwesomeIcon icon={faArrowAltCircleDown} />;
  };

  const getVoteType = () => {
    if (voteValue === 1) {
      return 'up-vote';
    }
    if (voteValue === -1) {
      return 'down-vote';
    }

    return '';
  };

  const renderComments = () => {
    if (post.errorComments) {
      return (
        <div>
          <h3>Error loading comments</h3>
        </div>
      );
    }

    if (post.loadingComments) {
      return (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      );
    }

    if (post.showingComments) {
      return (
        <div>
          {post.comments.map((comment) => (
            <Comment comment={comment} key={comment.id} />
          ))}
        </div>
      );
    }

    return null;
  };
  /*
          <div class="activity card" style="--delay: .2s">
          <div class="up-down-buttons">
            <button  class="icon-btn interactive" type="submit" onClick={} aria-label="Vote Up">
              <i class="far fa-arrow-circle-up"></i>
            </button>
            <div class="voteCount">583</div>
            <button  class="icon-btn interactive" type="submit" onClick={} aria-label="Vote Down">
              <i class="far fa-arrow-circle-down interactive"></i>
            </button>
          </div>

          <div class="result-content">
            <div class="card-header">
              <div class="titleSection">
                <div class="title">This is the title of the Reddit thread - it has something to do with kitten.</div>
                <div class="author">Peter Fischer</div>
              </div>
              <div class="timestamp">4m ago</div>
            </div>
            <div class="result-image"></div>
            <div class="card-footer interactive">  
              <button  class="comment-button icon-btn interactive" type="submit" onClick={} aria-label="Vote Down">
                <i class="far fa-comment"></i>   
                <div class="commentCount">583</div>   
              </button>
            </div>
            <div class="comment">
              <div class="textSection">
                <div class="author">Conscious-Coconut-16</div>
                <div class="timestamp">23m ago</div>
              </div>
              <div class="text">Street photography usually refers to candid photos of people in public. This is a photo of a street. It’s a good photo however it could be improved upon, and one way of achieving this is to get people into the photo. For example, go back and shoot this when people are leaving the theatre.</div>
            </div>
                        
          </div>
        </div>
  */


  return (
    <article key={post.id}>
      <Card className="activity card" >
          <div className="up-down-buttons">
            <button
              type="button"
              className={`icon-btn interactive up-vote ${
                voteValue === 1 && 'active'
              }`}
              onClick={() => onHandleVote(1)}
              aria-label="Up vote"
            >
              {renderUpVote()}
            </button>

            <p className={`voteCount ${getVoteType()}`}>
              {shortenNumber(post.ups, 1)}
            </p>


            <button
              type="button"
              className={`icon-btn interactive down-vote ${
                voteValue === -1 && 'active'
              }`}
              onClick={() => onHandleVote(-1)}
              aria-label="Down vote"
            >
              {renderDownVote()}
            </button>

          </div>

          <div className="result-content">
            <div className="card-header">

                <div className="title">{post.title}</div>
                <div className="titleSection">
                  <div className="author">{post.author}</div>
                  <div className="timestamp">{moment.unix(post.created_utc).fromNow()}</div>
                </div>
                
            </div>
            <div className="result-image">
            <img src={post.url} alt="" />
            </div>
            <div className="card-footer interactive">  

              <button
                  type="button"
                  className={`comment-button icon-btn interactive ${
                    post.showingComments && 'showing-comments'
                  }`}
                  onClick={() => onToggleComments(post.permalink)}
                  aria-label="Show comments"
                >
                  <FontAwesomeIcon icon={faComment} />
                  <div class="commentCount">{shortenNumber(post.num_comments, 1)}</div> 

              </button>

            </div>
            {renderComments()}
                        

 {/*
          <div className="result-content">
            <h3 className="post-title">{post.title}</h3>

            <div className="post-image-container">
              <img src={post.url} alt="" className="post-image" />
            </div>

            <div className="post-details">
              <span className="author-details">
                <Avatar name={post.author} />
                <span className="author-username">{post.author}</span>
              </span>
              <span>{moment.unix(post.created_utc).fromNow()}</span>
              
              <span className="post-comments-container">
                <button
                  type="button"
                  className={`icon-action-button ${
                    post.showingComments && 'showing-comments'
                  }`}
                  onClick={() => onToggleComments(post.permalink)}
                  aria-label="Show comments"
                >
                  <TiMessage className="icon-action" />
                </button>
                {shortenNumber(post.num_comments, 1)}
              </span>
            </div>

            {renderComments()}
          </div>
  */}       
        </div>
      </Card>
    </article>
  );
};

export default Post;
