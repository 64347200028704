import logo from './logo.svg';
import './App.css';
import Header from './features/Header/Header';
import Home from './features/Home/Home';
import Subreddits from './features/Subreddits/Subreddits';

function App() {
  return (
    <>
    <div className="wrapper">
      <div className="main-container">
        <Header />  
        <content className="content-area">
          <main className="result-box">
            <Home />
          </main>

          <aside className="subreddit-box second-box">
              <Subreddits />
          </aside>
        </content>
      </div>
    </div>
    </>
  );
}
/*
function App() {
  return (
    <>
      <Header />
      <main>
        <Home />
      </main>
      <aside>
        <Subreddits />
      </aside>
    </>
  );
}
*/

export default App;
