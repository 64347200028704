import React from 'react';
import Skeleton from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css'

//import './Post.css';
//import './PostLoading.css';
//import {  TiArrowUpOutline, TiArrowDownOutline, TiMessage,} from 'react-icons/ti';
import getRandomNumber from '../../utils/getRandomNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faArrowAltCircleDown, faComment } from '@fortawesome/free-solid-svg-icons'

const PostLoading = () => {
  return (
    <article className="activity loader">

      <div className="up-down-buttons">
            <button
              type="button"
              className="icon-btn interactive up-vote "
              aria-label="Up vote"
            >
              <FontAwesomeIcon icon={faArrowAltCircleUp} />
            </button>
            <div className="voteCount up-vote"> 
              <Skeleton width={30} />
            </div>
            <button
              type="button"
              className="icon-btn interactive down-vote "
              aria-label="Down vote"
            >
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
            </button>

          </div>


 {/*

      <div className="post-container">
        <h3 className="post-title">
          <Skeleton width={getRandomNumber(100, 200)} />
        </h3>

        <div className="post-image-container">
          <Skeleton height={250} />
        </div>

        <div className="post-details">
          <span>
            <Skeleton width={getRandomNumber(20, 50)} />
          </span>
          <span>
            <Skeleton width={getRandomNumber(50, 100)} />
          </span>
          <span className="post-comments-container">
            <button
              type="button"
              className="icon-action-button"
              aria-label="Show comments"
            >
              <TiMessage className="icon-action" />
            </button>
            <Skeleton width={getRandomNumber(10, 50)} />
          </span>
        </div>
      </div>

    */}  



      <div className="result-content">
            <div className="card-header">

                <div className="title">
                <Skeleton width={getRandomNumber(100, 200)} />
                </div>
                <div className="titleSection">
                  <div className="author"><Skeleton width={getRandomNumber(20, 40)} /></div>
                  <div className="timestamp"><Skeleton width={getRandomNumber(20, 40)} /></div>
                </div>
                
            </div>
            <div className="result-image">

            </div>
            <div className="card-footer interactive">  

              <button
                  type="button"
                  className="comment-button icon-btn interactive"
                  aria-label="Show comments"
                >
                  <FontAwesomeIcon icon={faComment} />
                  <div class="commentCount"><Skeleton width={getRandomNumber(7, 20)} /></div> 

              </button>

            </div>
            </div>                

    </article>
  );
};

export default PostLoading;
