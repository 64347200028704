import React, { useState, useEffect } from 'react';
//import { HiOutlineSearch } from 'react-icons/hi';
//import './Header.css';
import '../../index.css';
//import { FaReddit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchTerm } from '../../store/redditSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCat, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ReactMarkdown from "react-markdown";


const Header = () => {
  const [searchTermLocal, setSearchTermLocal] = useState('');
  const searchTerm = useSelector((state) => state.reddit.searchTerm);
  const dispatch = useDispatch();

  const onSearchTermChange = (e) => {
    setSearchTermLocal(e.target.value);
  };

  useEffect(() => {
    setSearchTermLocal(searchTerm);
  }, [searchTerm]);

  const onSearchTermSubmit = (e) => {
    e.preventDefault();
    dispatch(setSearchTerm(searchTermLocal));
  };
  

  return (
    <header className="header">
        <div className="logo">
            <FontAwesomeIcon icon={faCat} />
            CatReddit
        </div>
        <div className="search">
        <form className="search" onSubmit={onSearchTermSubmit}>
          <input
             type="text"
             placeholder="Search this list..."
             value={searchTermLocal}
             onChange={onSearchTermChange}
             aria-label="Search posts"
          />
          
          <button  className="icon-btn interactive" type="submit" onClick={onSearchTermSubmit} aria-label="Search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>

      <div className="result-info">
       {/*<button className="button">All
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
         <path d="M6 9l6 6 6-6" /></svg>
        </button>*/}

        {/* <div className="result-name">Seb's Profile</div> */}
        <div className="socialIcons">
          <a href="https://sebastianpreis.com" target="_blank" className="interactive"><FontAwesomeIcon icon={faGlobe} /></a>
          <a href="https://twitter.com/sebpreis" target="_blank" className="interactive"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="uk.linkedin.com/in/sebastianpreis" target="_blank" className="interactive"><FontAwesomeIcon icon={faLinkedin} /></a>
        </div>
      </div>

    </header>
  );
/*
  return (
    <header>
      <div className="logo">
        <FaReddit className="logo-icon" />
        <p>
          Reddit<span>Minimal</span>
        </p>
      </div>
      <form className="search" onSubmit={onSearchTermSubmit}>
        <input
          type="text"
          placeholder="Search"
          value={searchTermLocal}
          onChange={onSearchTermChange}
          aria-label="Search posts"
        />
        <button type="submit" onClick={onSearchTermSubmit} aria-label="Search">
          <HiOutlineSearch />
        </button>
      </form>
    </header>
  );
  */
};

export default Header;