import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { AnimatedList } from 'react-animated-list';
import Post from '../Post/Post';
import PostLoading from '../Post/PostLoading';
import getRandomNumber from '../../utils/getRandomNumber';
import {
  fetchPosts,
  selectFilteredPosts,
  setSearchTerm,
  fetchComments,
} from '../../store/redditSlice';
//import './Home.css';

const Home = () => {
  const reddit = useSelector((state) => state.reddit);
  const { isLoading, error, searchTerm, selectedSubreddit } = reddit;
  
  const posts = useSelector(selectFilteredPosts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosts(selectedSubreddit));
  }, [selectedSubreddit]);

  const onToggleComments = (index) => {
    const getComments = (permalink) => {
      dispatch(fetchComments(index, permalink));
    };

    return getComments;
  };
  
  if (isLoading) {
    return (
      //<AnimatedList animation="slide">      </AnimatedList>
      <div>
        {Array(getRandomNumber(3, 10)).fill(<PostLoading />)}
      </div>
    );
  }

  if (error) {
    return (
      <div className="activity card" >
      <div className="error title">
      <div className="title">Failed to load posts.</div>
        <button
          className="button"
          type="button"
          onClick={() => dispatch(fetchPosts(selectedSubreddit))}
        >
          Try again
        </button>
      </div>
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="activity card" >
        <div className="error title">
        <div className="title">No posts matching "{searchTerm}"</div>
        <button 
          className="button" 
          type="button" 
          onClick={() => dispatch(setSearchTerm(''))}
        >
          Go home
        </button>
      </div>
      </div>
    );
  }

  return (
    <>
      {posts.map((post, index) => (
        <Post
          key={post.id}
          post={post}
          onToggleComments={onToggleComments(index)}
        />
      ))}
    </>
  );
};

export default Home;
